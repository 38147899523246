<footer class="wrap p-3 shadow border-top h-100">
  <div class="container-fluid">
    <div class="row user-select-none">
      <div class="offset-4 col-2">
        <a routerLink="/terms" class="user-select-none">Terms</a>
      </div>
      <div class="col-2">
        <a routerLink="/privacy" class="user-select-none">Privacy</a>
      </div>
      <div class="col-4"></div>
    </div>
    <div class="row">
      <div class="col-12 user-select-none">
        uServe 2021 All Rights Reserved | Powered by Universal Processing LLC
      </div>
    </div>
  </div>
</footer>
