import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InitSettingsComponent } from 'src/app/pages/modals/init-settings/init-settings.component';
import { storageKeys } from 'src/app/share/configs';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  inquiryForm = this.fb.group({});
  keys: Record<string, string> = {};
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private winServe: WindowService
  ) {}
  setInquiry() {
    const params = Object.entries(this.route.snapshot.queryParams);
    const tableName:any={};
    let isBreak=false;
    console.log(params)
    for(const data of params){
         //for(const key of data){
            if(data[1]){
              tableName[data[0]]=data[1];
              isBreak=true;
            }
        // }
    }

      let show = false;
      params.forEach((p) => {
        !p[1] && !show && (show = true);
      });
      show
        ? this.openInquiry(params)
        : params.length
        ? this.saveInquiry(params)
        : null;
    isBreak && sessionStorage.setItem("TableName",JSON.stringify(tableName))

  }
  initInquiry(params: [string, any][]): void {
    params.forEach((p) => {
      const key = p[0].split('|');
      if (key.length > 1) {
        this.keys[key[0]] = key[1];
      }
      this.inquiryForm.addControl(
        key[0],
        this.fb.control(p[1], [Validators.required])
      );
    });
  }
  saveInquiry(params: [string, any][]) {
    this.initInquiry(params);
    this.winServe.setLocalStorage(
      storageKeys.inquiry,
      JSON.stringify(this.inquiryForm.value)
    );
  }
  openInquiry(params: [string, any][]) {
    this.initInquiry(params);
    const modalRef = this.modalService.open(InitSettingsComponent, {
      centered: true,
      scrollable: true,
    });
    modalRef.componentInstance.keys = this.keys;
    modalRef.componentInstance.settingsForm = this.inquiryForm;
    modalRef.componentInstance.params = params;
  }
}
