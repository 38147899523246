import { Injectable } from '@angular/core';
import { storageKeys } from 'src/app/share/configs';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class AnonymousService {
  anonymous_id: string | undefined;
  constructor(private winServe: WindowService) {}
  private GUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  setAnonymoudId(): string {
    if (!this.winServe.getLocalStorage(storageKeys.anonymous)) {
      this.anonymous_id = this.GUID();
      this.winServe.setLocalStorage(storageKeys.anonymous, this.anonymous_id);
    } else {
      this.anonymous_id = this.winServe.getLocalStorage(
        storageKeys.anonymous
      ) as string;
    }
    return this.anonymous_id;
  }
}
