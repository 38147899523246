<nav
  class="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow-sm border-bottom w-100"
>
  <div class="container-fluid">
    <button
      class="btn border d-lg-none shadow-sm"
      type="button"
      (click)="setInquiry()"
      aria-controls="navbarToggler"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="bi bi-gear"></i>
    </button>
    <a class="navbar-brand" [routerLink]="['/home']">
      <b class="text-danger user-select-none">uOrder</b>
    </a>
    <div
      (click)="handleOutsideClick($event)"
      class="collapse navbar-collapse mt-1 bg-light border-end"
      id="navbarToggler"
    ></div>

    <button
      class="shoppping-cart-button btn btn-danger shadow-sm"
      type="button"
      (click)="getAnonymousShoppingCart()"
    >
      <div class="pointer-event align-items-center d-flex">
        <i class="bi bi-cart"></i>
        <ng-container *ngIf="!loading; else loadingTemplate">
          <span class="fw-bold ms-2">{{ length || 0 }}</span></ng-container
        >
      </div>
    </button>
  </div>
</nav>
<div
  [ngClass]="{ visible: showNavbar }"
  class="offcanvas offcanvas-start d-lg-none"
  aria-labelledby="navOffcanvas"
>
  <div class="offcanvas-header">
    <a class="offcanvas-title fs-2 fw-bold text-danger" [routerLink]="['/home']"
      >uOrder</a
    >
    <button
      type="button"
      class="btn-close text-reset"
      (click)="showNavbar = false"
      aria-label="Close"
    ></button>
  </div>
</div>
<uo-shopping-cart
  #shoppingCart
  [store]="store"
  [shoppingCartStoreId]="shoppingCartStoreId"
  [total]="total"
  [length]="length"
  [isLog]="isLog"
  [show]="showShoppingCart"
  (hide)="showShoppingCart = false"
></uo-shopping-cart>
<ng-template #loadingTemplate>
  <div class="spinner-border spinner-border-sm ms-2 z-1099" role="status">
    <span class="visually-hidden">...</span>
  </div></ng-template
>
