import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitSettingsComponent } from './init-settings.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [InitSettingsComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModalModule],
  exports: [InitSettingsComponent],
})
export class InitSettingsModule {}
