export const storageKeys = {
  inquiry: 'inquiry',
  shoppingCartStore: 'shoppingCartStoreId',
  store: 'storeId',
  user: 'userId',
  lastActionTime: 'lastClickTime',
  merchant: 'merchantId',
  anonymous: 'anonymous_id',
  orderStatus: 'orderStatus',
  payment: 'payment',
  storeInfo: 'storeInfo',
  searchRadius: 'searchRadius',
  userFirstName: 'userFirstName',
  userLastName: 'userLastName',
};
