import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WindowService } from 'src/app/services/local/window.service';
import { storageKeys } from 'src/app/share/configs';

@Component({
  selector: 'uo-init-settings',
  templateUrl: './init-settings.component.html',
  styleUrls: ['./init-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitSettingsComponent implements OnInit {
  params!: string[][];
  keys: Record<string, string> = {};
  settingsForm = this.fb.group({});

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private winServe: WindowService
  ) {}
  ngOnInit(): void {}
  update(): void {
    //console.log(this.settingsForm.value)
    // console.log(
    //   'serttingform.value :>> ',
    //   JSON.stringify(this.settingsForm.value)
    // );
    // this.winServe.setSessionStorage(
    //   storageKeys.inquiry,
    //   JSON.stringify(this.settingsForm.value)
    // );
    sessionStorage.setItem("TableName",JSON.stringify(this.settingsForm.value))
    this.activeModal.close(true);
  }
}
