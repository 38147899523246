<div class="wrap pb-3">
  <div class="container-fluid">
    <div class="row pt-3 pb-5 bg-danger home-header-bg relative">
      <div class="col-12 d-flex justify-content-center user-select-none pt-5">
        <div class="col-8 col-md-6 col-lg-4">
          <svg
            preserveAspectRatio="xMidYMid meet"
            data-bbox="0 0 322.4 69.91"
            viewBox="0 0 322.4 69.91"
            data-type="color"
            role="presentation"
            aria-hidden="true"
          >
            <g>
              <path
                d="M142.6 21.1c0 10-5.7 15.2-14.3 15.2S114 31.1 114 21.4v-20h3.9v19.8c0 7.4 3.9 11.6 10.5 11.6 6.3 0 10.4-3.8 10.4-11.3v-20h3.9v19.6z"
                fill="#FEFEFA"
                data-color="1"
              ></path>
              <path
                d="M154.2 35.8h-3.8V10.4h3.8v4.4c1.7-3.2 5.1-5.1 8.7-5 6.1 0 9.7 4.1 9.7 10.2v15.8h-3.8V21c0-4.7-2.6-7.7-7-7.7s-7.6 3.2-7.6 8v14.5z"
                fill="#FEFEFA"
                data-color="1"
              ></path>
              <path
                d="M180.5 4.9V.7h4.3v4.2h-4.3zm.3 30.9V10.4h3.8v25.4h-3.8z"
                fill="#FEFEFA"
                data-color="1"
              ></path>
              <path
                d="M210.9 10.4h4.1L204 36h-3.4l-11-25.6h4.2l8.5 21 8.6-21z"
                fill="#FEFEFA"
                data-color="1"
              ></path>
              <path
                d="M229.9 33.1c3.1 0 6-1.3 8-3.6l2.3 2.2c-2.6 3.1-6.4 4.9-10.4 4.8-7 0-12.6-5.3-12.6-13.2 0-7.4 5.1-13.2 12.2-13.2 7.5 0 11.8 6 11.8 13.5v1.2H221c.5 5.2 4.4 8.3 8.9 8.3zm7.4-11.4c-.4-4.6-3-8.6-8.1-8.6-4.4 0-7.8 3.7-8.2 8.6h16.3z"
                fill="#FEFEFA"
                data-color="1"
              ></path>
              <path
                d="M251.3 35.8h-3.8V10.4h3.8V17c1.9-4.2 5.5-7.3 10.3-7.1V14h-.3c-5.5 0-10 4-10 11.6v10.2z"
                fill="#FEFEFA"
                data-color="1"
              ></path>
              <path
                d="M274.8 36.3c-3.8-.1-7.5-1.4-10.5-3.7l1.9-2.7c2.5 2 5.6 3.1 8.8 3.2 3 0 5.2-1.6 5.2-4V29c0-2.5-3-3.5-6.3-4.5-4-1.1-8.4-2.5-8.4-7.2v-.1c0-4.4 3.6-7.3 8.6-7.3 3.2.1 6.4 1 9.1 2.8l-1.7 2.9c-2.2-1.5-4.8-2.4-7.5-2.5-3 0-4.9 1.6-4.9 3.7v.2c0 2.4 3.1 3.3 6.5 4.4 3.9 1.2 8.1 2.7 8.1 7.3.1 4.8-3.9 7.6-8.9 7.6z"
                fill="#FEFEFA"
                data-color="1"
              ></path>
              <path
                d="M292 15.3l-1.1-3.1c2.9-1.4 6-2.1 9.2-2.1 6.9 0 10.8 3.5 10.8 10.2v15.5h-3.6V32c-2.2 2.9-5.6 4.5-9.2 4.4-4.7 0-9.5-2.7-9.5-8s4.4-8.2 10.8-8.2c2.6 0 5.3.3 7.8 1.1v-.9c0-4.6-2.8-6.9-7.6-6.9-2.6-.1-5.2.6-7.6 1.8zm.5 13c0 3.2 3 5.1 6.4 5.1 4.7 0 8.5-2.8 8.5-6.9V24c-2.4-.7-4.9-1.1-7.5-1.1-4.8.1-7.4 2.1-7.4 5.4z"
                fill="#FEFEFA"
                data-color="1"
              ></path>
              <path
                d="M318.6 35.8V0h3.8v35.8h-3.8z"
                fill="#FEFEFA"
                data-color="1"
              ></path>
              <path
                d="M120 54.2h-4V60h-1.8V43.6h6.1c3.7 0 6.1 2 6.1 5.2 0 3.6-3 5.4-6.4 5.4zm.1-8.9H116v7.2h4c2.7 0 4.5-1.4 4.5-3.6 0-2.4-1.7-3.6-4.4-3.6z"
                fill="#FFFFF0"
                data-color="2"
              ></path>
              <path
                d="M147 59.9l-4.7-6.3h-4.7v6.3h-1.8V43.6h7c3.6 0 5.9 1.9 5.9 4.9 0 2.7-1.9 4.3-4.4 4.8l5 6.7H147zm-4.4-14.6h-5v6.6h5c2.4 0 4.2-1.3 4.2-3.4s-1.5-3.2-4.2-3.2z"
                fill="#FFFFF0"
                data-color="2"
              ></path>
              <path
                d="M166.2 60.2c-4.7 0-8.5-3.7-8.5-8.4s3.7-8.5 8.4-8.5 8.5 3.7 8.5 8.4c.1 4.6-3.5 8.4-8.1 8.5h-.3zm0-15.2c-3.5 0-6.4 2.9-6.4 6.4v.3c-.1 3.6 2.6 6.6 6.2 6.8h.2c3.5 0 6.4-2.9 6.4-6.5v-.2c.2-3.6-2.6-6.6-6.2-6.8h-.2z"
                fill="#FFFFF0"
                data-color="2"
              ></path>
              <path
                d="M191.7 60.2c-4.7-.2-8.3-4.1-8.1-8.8.2-4.4 3.7-8 8.2-8.1 2.4-.1 4.7.9 6.4 2.6l-1.3 1.3c-1.3-1.4-3.2-2.2-5.1-2.2-3.7.2-6.6 3.4-6.4 7.1.2 3.4 2.9 6.2 6.4 6.4 2 0 3.9-.9 5.3-2.3l1.2 1.2c-1.7 1.8-4.1 2.9-6.6 2.8z"
                fill="#FFFFF0"
                data-color="2"
              ></path>
              <path
                d="M219.3 45.3h-10v5.6h8.9v1.7h-8.9v5.7h10.1V60h-11.9V43.6h11.8v1.7z"
                fill="#FFFFF0"
                data-color="2"
              ></path>
              <path
                d="M235 50.9c3.6.8 5.3 2.1 5.3 4.6 0 2.8-2.3 4.6-5.6 4.6-2.5 0-4.9-.9-6.7-2.6l1.1-1.3c1.5 1.5 3.5 2.3 5.6 2.3 2.2 0 3.6-1.1 3.6-2.8s-.8-2.4-4.2-3.1c-3.8-.8-5.5-2-5.5-4.7s2.3-4.5 5.4-4.5c2.1-.1 4.1.7 5.7 2l-1.1 1.4c-1.3-1.1-3-1.8-4.7-1.8-2.1 0-3.5 1.2-3.5 2.7s1 2.4 4.6 3.2z"
                fill="#FFFFF0"
                data-color="2"
              ></path>
              <path
                d="M255.9 50.9c3.6.8 5.3 2.1 5.3 4.6 0 2.8-2.3 4.6-5.6 4.6-2.5 0-4.9-.9-6.7-2.6l1.1-1.3c1.5 1.5 3.5 2.3 5.6 2.3 2.2 0 3.6-1.1 3.6-2.8s-.8-2.4-4.3-3.1c-3.8-.8-5.5-2-5.5-4.7s2.3-4.5 5.4-4.5c2.1-.1 4.1.7 5.7 2l-1.1 1.4c-1.3-1.1-3-1.8-4.7-1.8-2.1 0-3.5 1.2-3.5 2.7s1.1 2.4 4.7 3.2z"
                fill="#FFFFF0"
                data-color="2"
              ></path>
              <path
                d="M273 43.6v16.3h-1.9V43.6h1.9z"
                fill="#FFFFF0"
                data-color="2"
              ></path>
              <path
                d="M295.8 43.6h1.8v16.3h-1.5l-10.5-13.4v13.4h-1.8V43.6h1.7l10.3 13.1V43.6z"
                fill="#FFFFF0"
                data-color="2"
              ></path>
              <path
                d="M315.9 60.2c-5.1 0-8.3-3.7-8.3-8.4-.1-4.5 3.4-8.3 7.9-8.5h.3c2.2-.1 4.4.7 6.1 2.2l-1.2 1.4c-1.4-1.3-3.2-1.9-5-1.9-3.6 0-6.2 3.1-6.2 6.7 0 3.9 2.5 6.8 6.5 6.8 1.7 0 3.4-.6 4.7-1.6v-4.1h-5v-1.7h6.7v6.5c-1.8 1.7-4.1 2.6-6.5 2.6z"
                fill="#FFFFF0"
                data-color="2"
              ></path>
              <path
                d="M0 43c0 7.1 2 10.6 3.2 12l.2.2c.2.3.5.5.8.7C8.6 60.6 16.8 60 16.8 60H47V1.4H0V43z"
                fill="#FEFEFA"
                data-color="1"
              ></path>
              <path
                d="M62.4 1.4c.4 0-4.9-.2-7.7 1.5-3.5 2.1-3.3 8.7-3.3 8.7v56.1s0 1.8.9 2.1c0 0 1 .6 2.7-1l6.6-7.7c.6-.7 1.6-1.1 2.6-1.1h17.4s8 .5 12.5-3.9l.5-.4s.1 0 .1-.1c1-1 3.8-4.3 3.8-12.6V1.4H62.4z"
                fill="#FFFFF0"
                data-color="2"
              ></path>
            </g>
          </svg>
        </div>
      </div>
      <div
        class="col-12 d-md-none d-flex justify-content-center text-center text-white fs-1 fw-500 text-default user-select-none pt-2"
      >
        YOU ORDER,
      </div>
      <div
        class="col-12 d-md-none d-flex justify-content-center text-center text-white fs-1 fw-500 text-default user-select-none pb-2"
      >
        WE ARE HERE.
      </div>
      <div
        class="col-12 d-none d-md-block d-flex justify-content-center text-center text-light fs-1 fw-500 text-default user-select-none pb-2 pt-2"
      >
        YOU ORDER, WE ARE HERE.
      </div>
    </div>
  </div>
</div>
<uo-footer></uo-footer>
<div class="clearfix" *ngIf="loading">
  <div class="spinner-wrap fixed-top">
    <div class="spinner-border spinner position-fixed" role="status"></div>
  </div>
</div>
