<div class="modal-header">
  <h5 class="modal-title">Settings</h5>
  <button
    type="button"
    class="close btn"
    aria-label="Close"
    (click)="activeModal.close(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form class="modal-body" [formGroup]="settingsForm">
  <div class="container-fluid">
    <div
      class="row mb-2"
      *ngFor="let control of settingsForm.controls | keyvalue; let i = index"
    >
      <div class="input-group">
        <span class="input-group-text min-w">{{
          keys[control.key] ? keys[control.key] : control.key
        }}</span>
        <input
          maxlength="20"
          type="text"
          class="form-control"
          [class.is-invalid]="control.value!.touched && control.value!.invalid"
          [class.is-valid]="control.value!.touched && control.value!.valid"
          [placeholder]="control.value.value"
          [formControlName]="control.key"
        />
      </div>
      <div
        *ngIf="control.value!.dirty && control.value!.errors as errors"
        class="w-100 fw-500"
      >
        <span class="text-danger user-select-none" *ngIf="errors?.['required']"
          >Phone is required</span
        >
        <span class="text-danger user-select-none" *ngIf="errors?.['minlength']"
          >Please input valid phone format</span
        >
      </div>
    </div>
  </div>
</form>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close(false)"
  >
    Close
  </button>
  <button
    type="button"
    class="btn btn-danger"
    [disabled]="!settingsForm.valid"
    (click)="update()"
  >
    Update
  </button>
</div>
