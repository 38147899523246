import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { StoreRes } from 'src/app/share/types';

@Component({
  selector: 'uo-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  loading: boolean = false;
  predictShow: boolean = false;
  restaurantUrl: string = '';
  placePredictions!: google.maps.places.AutocompletePrediction[] | null;
  placePrediction: google.maps.places.AutocompletePrediction | null = null;
  addressStr: string = '';
  stores!: StoreRes[];

  constructor() {}
  ngOnDestroy(): void {}
  ngAfterViewInit(): void {}

  ngOnInit(): void {}
}
