<uo-header></uo-header>
<div class="min-vh-100" #storeContent>
  <!-- spinner -->
  <div class="clearfix" *ngIf="loading">
    <div
      class="spinner-wrap fixed-top d-flex align-items-center justify-content-center"
    >
      <div class="spinner-border spinner" role="status"></div>
    </div>
  </div>
  <div class="fill-up mt-xl-4"></div>
  <!-- restaurant information conponents -->
  <uo-restaurant-info (heightEvent)="height = $event"></uo-restaurant-info>
  <!-- menu categories -->
  <uo-menu
    [height]="height"
    [contentHeight]="contentView.nativeElement.offsetHeight"
  ></uo-menu>
</div>
<uo-full-footer></uo-full-footer>
