import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemModalComponent } from './item-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SizerModule } from './sizer/sizer.module';
import { ModifyArrayModule } from './modify-array/modify-array.module';
import { SectionArrayModule } from './section-array/section-array.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ItemModalComponent],
  imports: [
    CommonModule,
    NgbModalModule,
    SizerModule,
    ModifyArrayModule,
    SectionArrayModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [ItemModalComponent],
})
export class ItemModalModule {}
